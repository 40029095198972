import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Homepage from "./pages/Homepage";
//import Blog from "./pages/Blog";
//import SinglePost from "./pages/SinglePost";
import Error from "./pages/Error";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./traslation/es/global.json";
import global_en from "./traslation/en/global.json";

import { useCookies } from "react-cookie";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const ThemeContext = createContext();
export const Context = createContext({});

function App() {
  const [cookies, setCookies] = useCookies(["cookies"]);
  const [language, setLanguage] = useState("es");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#075B69",
        darker: "#ffffff",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#687690",
      },
    },
    typography: {
      fontFamily: "Merriweather Sans, serif",
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#11A2BB",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&:before": {
              borderBottomColor: "#001b3d",
            },
            "&:after": {
              borderBottomColor: "#075B69",
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            justifyContent: "center",
            margin: "20px",
          },
        },
      },
    },
  });

  useEffect(() => {
    if (!cookies.language) {
      setCookies("language", "es");
    } else if (cookies.language === "es") {
      setLanguage("es");
    } else if (cookies.language === "en") {
      setLanguage("en");
    }
  }, []);

  const handleLanguageChange = () => {
    setLanguage(language === "en" ? "es" : "en");
  };

  useEffect(() => {
    setCookies("language", language);
  }, [language]);

  useEffect(() => {
    document.body.style.margin = "0";
  }, []);

  i18next.init({
    interpolation: { escapeValue: false },
    lng: language,
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
    },
  });
  return (
    <Context.Provider
      value={{
        setLanguage,
        handleLanguageChange,
        language
      }}
      sx={{ margin: "0" }}
    >
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Homepage />} exact></Route>
              {/* <Route path="/blog/:slug" element={<SinglePost />}></Route>
              <Route path="/blog" element={<Blog />}></Route> */}
              <Route path="*" element={<Error />}></Route>
            </Routes>
            <Footer />
          </BrowserRouter>
        </I18nextProvider>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;
