import React from "react";
import { Context } from "../App";

import {
  Box,
  styled,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";

import LogoCatarsis from "../media/Frame 99.svg";

import { useTranslation } from "react-i18next";

export default function Homepage() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(4),
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexDirection: "column-reverse"
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#000000", //color: "#687690"
    fontWeight: "bold",
    fontFamily: "Lato",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  return (
    <Context.Consumer>
      {({ setLanguage, theme }) => (
        <div>
          <Box
            id="weAre"
            sx={{
              backgroundColor: "#ffffff",
              minHeight: "60vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box
                  sx={{
                    flex: "0.5",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    mt: "20px",
                  }}
                >
                  <img
                    src={LogoCatarsis}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
                <Box sx={{ flex: "0.6", marginTop: 9 }}>
                  <Title variant="h1">{t("weAre.welcome")}</Title>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      mt: 4,
                    }}
                  >
                    {t("weAre.text")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
        </div>
      )}
    </Context.Consumer>
  );
}
