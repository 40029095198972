import React from "react";
import { Context } from "../App";
import { Box, styled, Typography } from "@mui/material";
import Container from "@mui/material/Container";

import { useTranslation } from "react-i18next";
import construccion from "../media/construccion2.jpg";

export default function Error() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(5),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));
  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "3rem",
    color: "#687690",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));
  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  return (
    <Context.Consumer>
      {({ setLanguage }) => (
        <Box sx={{ backgroundColor: "#ffffff", minHeight: "65vh" }}>
          <Container>
            <CustomBox>
              <Box sx={{ flex: "1" }}>
                <Title variant="h1"> {t("error.construccion")}</Title>
                <Paragraph
                  variant="body2"
                  sx={{
                    fontSize: "1.5rem",
                    color: "#687690",
                    fontWeight: "500",
                    zIndex: 5,
                  }}
                >
                  {t("error.text")}
                </Paragraph>
                <Box
                  sx={{
                    img: {
                      width: {
                        xs: "100%", // Para dispositivos extra pequeños
                        sm: "80%", // Para otros tamaños de pantalla
                        md: "50%",
                        xl: "50%",
                      },
                    },
                  }}
                >
                  <img src={construccion} alt="construccion" />
                </Box>
              </Box>
            </CustomBox>
          </Container>
        </Box>
      )}
    </Context.Consumer>
  );
}
