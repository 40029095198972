import React from "react";
import { Context } from "../App";

import {
  Box,
  styled,
  Typography,
  Input,
  TextField,
  Paper,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";

import Catarsis from "../media/Group 11.svg";

import LogoCatarsis from "../media/Logo Catarsis.svg";
import FrameDr from "../media/Vector 18FR.svg";
import FrameIz from "../media/Vector 17FR.png";

import { useTranslation } from "react-i18next";

export default function Hero() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(4),
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#000000", //color: "#687690"
    fontWeight: "bold",
    fontFamily: "Lato",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
  }));
  const CustomButton = styled(Button)({
    backgroundColor: '#4852B3',
    borderRadius:"10px",
    color: 'white',
    '&:hover': {
      backgroundColor: '#303a82', // Color de fondo al pasar el ratón
    },
  });

  return (
    <Context.Consumer>
      {({ setLanguage, theme }) => (
        <div>
          {/* Catarsis lab */}
          <Box
            sx={{
              background:
                "linear-gradient(180deg, rgba(227, 247, 254, 0.5) 39.14%, rgba(255, 255, 255, 0) 100%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
              minHeight: "40vh",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box>
                  <Box sx={{ flex: "1.25", mt: 17, mb: 4 }}>
                    <img
                      src={Catarsis}
                      alt="Catarsis"
                      style={{ width: "50%", marginBottom: "0.5rem" }}
                    />
                  </Box>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontSize: "2.0rem",
                      fontWeight: "500",
                    }}
                  >
                    {t("hero.text")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box sx={{ mt: "-15%", textAlign: "center" }}>
            <img
              src={LogoCatarsis}
              alt="LogoCatarsis"
              style={{ width: "100%" }}
            />
          </Box>
          {/* What is Catarsis Lab? */}
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box
                  sx={{ flex: "1", mt: "50px", textAlign: "center", zIndex: 1 }}
                >
                  <Title variant="h1">{t("hero.question")}</Title>
                  <CustomButton component="a" href="/shop" variant="contained">{t("hero.button")}</CustomButton>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              mt: "-15%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <img src={FrameIz} alt="manch Rosa" style={{ width: "20%" }} />
            <img src={FrameDr} alt="manch Rosa" style={{ width: "20%" }} />
          </Box>
        </div>
      )}
    </Context.Consumer>
  );
}
