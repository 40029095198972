import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {
  styled,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";

export default function Newsletterform() {
  const [state, handleSubmit] = useForm("mrgnavla");
  const itemsStyle = {
    marginTop: "10px",
    color: "string",
    backgroundColor: "#ffffff",
    //borderRadius: "20px",
    maxWidth: "80%",
  };
  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#ffffff",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));
  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    color: "#ffffff", 
    fontWeight: "bold",
    fontFamily: "Lato",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  const [t, i18n] = useTranslation("global");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <Title>{t("footer.title")}</Title>
      <Paragraph sx={{ fontWeight: "500" }}>
      {t("footer.text")}
      </Paragraph>
      <label htmlFor="email"></label>
      <TextField
        sx={itemsStyle}
        variant="outlined"
        placeholder={t("form.email")}
        id="email"
        type="email"
        name="email"
        fullWidth
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      <IconButton
        type="submit"
        disabled={state.submitting}
        sx={{
          width: "50px", 
          height: "50px", 
          backgroundColor: "#4852B3",
          borderRadius: "10px",
          mt: "13px ",
          ml: "5px",
        }}
      >
        <ArrowForwardIcon />
      </IconButton>
    </form>
  );
}
