import React, { useState } from "react";
import { Context } from "../App";
import CustomCarousel from "../components/Carrousel";
import Container from "@mui/material/Container";
import { Box, styled, Typography, Paper, Button } from "@mui/material";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";

//Images
import plane from "../media/avion.png";
import planePhone from "../media/plane.png";
import catarsisGriego from "../media/Frame 134.svg";
import CatarsisIZ from "../media/vector 17A.svg";
import CatarsisDR from "../media/vector 17B.svg";

//pages
import Hero from "./Hero";
import WeAre from "./Weare";
import Experiences from "./Experiences";

export default function Homepage() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(4),
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#000000", //color: "#687690"
    fontWeight: "bold",
    fontFamily: "Lato",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  const CustomButton = styled(Button)({
    backgroundColor: "#4852B3",
    borderRadius: "10px",
    color: "white",
    "&:hover": {
      backgroundColor: "#303a82",
    },
  });
  //carrousel
  const [selectedCarrouselPage, setSelectedCarrouselPage] = useState(0);

  const handleSlideChange = (newIndex) => {
    setSelectedCarrouselPage(newIndex);
  };

  return (
    <Context.Consumer>
      {({ setLanguage, theme }) => (
        <div>
          <Box id="heroSection">
            <Hero />
          </Box>
          <Box id="weAre">
            <WeAre />
          </Box>
          {/* plane seccion */}
          <Box
            sx={{
              backgroundColor: "#F2FFF8",
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "1", mt: 7, mb: 4, textAlign: "center" }}>
                  <Title variant="h1">{t("mission.mission")}</Title>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Lato",
                    }}
                  >
                    {t("mission.laboratory")}
                  </Paragraph>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontFamily: "Lato",
                    }}
                  >
                    {t("mission.discover")}
                  </Paragraph>
                  <CustomButton component="a" href="/shop" variant="contained">
                    {t("mission.button")}
                  </CustomButton>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              zIndex: -10,
              backgroundColor: "#F2FFF8",
              mt: {
                sm: "-230px",
                md: "-260px",
                xl: "-340px",
              },
              img: {
                width: {
                  xs: "0%",
                  sm: "30%",
                  md: "25%",
                  xl: "20%",
                },
              },
            }}
          >
            <img src={plane} alt="plane" />
          </Box>
          <Box
            sx={{
              display: "flex",
              zIndex: -10,
              backgroundColor: "#F2FFF8",
              img: {
                width: {
                  xs: "60%",
                  sm: "0%",
                  md: "0%",
                  xl: "0%",
                },
              },
            }}
          >
            <img src={planePhone} alt="plane" />
          </Box>
          {/* carrousel seccion */}
          <Box
            sx={{
              backgroundColor: "#F2FFF8",
              minHeight: "60vh",
              display: "flex",
              alignItems: "center",
              mt: {
                sm: "-70px", 
                md: "-100px", 
                xl: "-100px",
              },
            }}
          >
            <Container>
              <CustomBox>
                <Box
                  sx={{
                    flex: "1",
                    mt: 10,
                    mb: 4,
                    textAlign: "center",
                    "@media (max-width: 600px)": {
                      mt: 0,
                      mb: 0,
                    },
                  }}
                >
                  <Title variant="h1">{t("mission.travel")}</Title>
                  <Title variant="h1" sx={{ color: "#6E68FC" }}>
                    {t("mission.ready")}
                  </Title>
                </Box>
                <Box sx={{ flex: "1" }}>
                  <Title variant="h1">{t("mission.explorers")}</Title>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "500",
                      mt: 3,
                      mb: 4,
                    }}
                  >
                    {t("mission.catalab")} <br></br> {t("mission.catalab2")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Title variant="h1">{t("carousel.title")}</Title>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              minHeight: "70vh",
              "@media (max-width: 600px)": {
                minHeight: "50vh",
              },
              mt: -5,
            }}
          >
            <Container>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 6,
                  "@media (max-width: 600px)": {
                    display: "grid",
                    alignItems: "center",
                    textAlign: "center",
                  },
                }}
              >
                <Box
                  sx={{ flex: "0.6", display: "grid", alignItems: "center" }}
                >
                  <CustomCarousel onChange={handleSlideChange} />
                </Box>
                <Box sx={{ flex: "0.6" }}>
                  <Paper
                    elevation={0 === selectedCarrouselPage ? 10 : 0}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#E3F7FE",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      maxWidth: "100%",
                      padding: "15px",
                      mb: 2,
                      borderColor:
                        0 === selectedCarrouselPage ? "#85DEFE" : "#E3F7FE",
                      "@media (max-width: 600px)": {
                        display: selectedCarrouselPage === 0 ? "grid" : "none",
                        height: "120px",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography variant="h5">
                      {t("carousel.felling")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "1.2rem", fontWeight: 100 }}
                    >
                      {t("carousel.fellText")}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={1 === selectedCarrouselPage ? 10 : 0}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#B7FED6",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      maxWidth: "100%",
                      padding: "15px",
                      mb: 2,
                      borderColor:
                        1 === selectedCarrouselPage ? "#29D876" : "#B7FED6",
                      "@media (max-width: 600px)": {
                        display: selectedCarrouselPage === 1 ? "grid" : "none",
                        height: "120px",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography variant="h5">
                      {t("carousel.reconfigure")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "1.2rem", fontWeight: 100 }}
                    >
                      {t("carousel.reconfigureText")}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={2 === selectedCarrouselPage ? 10 : 0}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#FFF3DD",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      maxWidth: "100%",
                      padding: "15px",
                      mb: 2,
                      borderColor:
                        2 === selectedCarrouselPage ? "#FAB333" : "#FFF3DD",
                      "@media (max-width: 600px)": {
                        display: selectedCarrouselPage === 2 ? "grid" : "none",
                        height: "120px",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography variant="h5">
                      {t("carousel.reinterpret")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "1.2rem", fontWeight: 100 }}
                    >
                      {t("carousel.reinterpretText")}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={3 === selectedCarrouselPage ? 10 : 0}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#FFF1F9",
                      borderRadius: "16px",
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      maxWidth: "100%",
                      padding: "15px",
                      mb: 2,
                      borderColor:
                        3 === selectedCarrouselPage ? "#FF85CB" : "#FFF1F9",
                      "@media (max-width: 600px)": {
                        display: selectedCarrouselPage === 3 ? "grid" : "none",
                        height: "120px",
                        alignItems: "center",
                      },
                    }}
                  >
                    <Typography variant="h5">{t("carousel.create")}</Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "1.2rem", fontWeight: 100 }}
                    >
                      {t("carousel.createText")}
                    </Typography>
                  </Paper>
                </Box>
              </Box>
            </Container>
          </Box>
          {/* Eperiences seccion */}
          <Box id="experience">
            <Experiences />
          </Box>
          {/* Catarsis definition seccion */}
          <Box
            sx={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box
                  sx={{
                    flex: "1",
                    mt: 7,
                    mb: 4,
                    textAlign: "center",
                    zIndex: 5,
                  }}
                >
                  <img
                    src={catarsisGriego}
                    alt="Logo"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontFamily: "Lato",
                    }}
                  >
                    {t("catarsis.subTitle")}
                  </Paragraph>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Lato",
                    }}
                  >
                    {t("catarsis.text")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              zIndex: -10,
              mt: {
                xs: "-360px",
                sm: "-370px",
                md: "-450px",
                xl: "-470px",
              },
              mb: {
                xs: "200px",
                sm: "195px",
                md: "185px",
                xl: "180px",
              },
              img: {
                width: {
                  xs: "30%",
                  xl: "20%",
                },
              },
            }}
          >
            <img src={CatarsisIZ} alt="frame grey catarsis" />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              zIndex: -10,
              mt: {
                xs: "-60px", 
                sm: "-150px",
                md: "-165px", 
                xl: "-170px",
              },
              mb: {
                xs: "35px", 
                md: "10px",
                xl: "10px",
              },
              img: {
                width: {
                  xs: "20%", 
                  xl: "20%",
                },
              },
            }}
          >
            <img src={CatarsisDR} alt="frame grey catarsis" />
          </Box>
          {/* This is for you seccion */}
          <Box
            id="thisIsForYou"
            sx={{
              backgroundColor: "#F7F8FF",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              "@media (max-width: 600px)": {
                mb: "-50px",
              },
            }}
          >
            <Container>
              <CustomBox>
                <Box>
                  <Box sx={{ flex: "1" }}>
                    <Title variant="h1">{t("thisIsForYou.title")}</Title>
                  </Box>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              backgroundColor: "#F7F8FF",
              minHeight: "60vh",
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "0.9", textAlign: "left", mt: "-5%" }}>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      margin: "1rem 0",
                      textIndent: "-1rem",
                    }}
                  >
                    • {t("thisIsForYou.text1")}
                  </Paragraph>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      margin: "1rem 0",
                      textIndent: "-1rem",
                    }}
                  >
                    • {t("thisIsForYou.text2")}
                  </Paragraph>
                </Box>
                <Box
                  sx={{
                    flex: "0.9",
                    textAlign: "left",
                    mt: "-5%",
                    "@media (max-width: 600px)": {
                      mt: "-16%",
                    },
                  }}
                >
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      margin: "1rem 0",
                      textIndent: "-1rem",
                    }}
                  >
                    • {t("thisIsForYou.text3")}
                  </Paragraph>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      margin: "1rem 0",
                      textIndent: "-1rem",
                    }}
                  >
                    • {t("thisIsForYou.text4")}
                  </Paragraph>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      margin: "1rem 0",
                      textIndent: "-1rem",
                    }}
                  >
                    • {t("thisIsForYou.text5")}
                  </Paragraph>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      margin: "1rem 0",
                      textIndent: "-1rem",
                    }}
                  >
                    • {t("thisIsForYou.text6")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          {/* contact seccion */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              zIndex: 3,
              marginTop: "3%",
              marginBottom: "8%",
            }}
          >
            <Container>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "@media (max-width: 600px)": {
                    display: "grid",
                    alignItems: "center",
                    textAlign: "center",
                  },
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 5,
                  }}
                >
                  <Title variant="h1">{t("form.title")}</Title>
                  <ContactForm />
                </Box>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              zIndex: -10,
              mt: {
                xs: "-360px", 
                sm: "-370px", 
                md: "-450px", 
                xl: "-470px",
              },
              mb: {
                xs: "200px",
                sm: "195px", 
                md: "185px",
                xl: "100px",
              },
              img: {
                width: {
                  xs: "30%", 
                  xl: "20%",
                },
              },
            }}
          >
            <img src={CatarsisIZ} alt="frame grey catarsis" />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              zIndex: -10,
              mt: {
                xs: "-50px", 
                sm: "-150px", 
                md: "-130x", 
                xl: "-130px",
              },
              mb: {
                xs: "35px", 
                md: "35px",
                xl: "35px",
              },
              img: {
                width: {
                  xs: "30%",
                  md: "20%",
                  xl: "20%",
                },
              },
            }}
          >
            <img src={CatarsisDR} alt="frame grey catarsis" />
          </Box>
        </div>
      )}
    </Context.Consumer>
  );
}
