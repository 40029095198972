import React from "react";

import {
    styled,
    Typography,
    Input,
    TextField,
    Paper,
    Button,
    Container
  } from "@mui/material";

import { useForm, ValidationError } from "@formspree/react";
import { useTranslation } from "react-i18next";

export default function ContactForm() {
    const [state, handleSubmit] = useForm("mnqyleqj");
    const itemsStyle = {
      marginTop: "10px",
      color: "string",
    };
    const paperStyle = {
      padding: 20,
      margin: 20,
    };
    const Title = styled(Typography)(({ theme }) => ({
      fontSize: "2.5rem",
      color: "#687690",
      fontWeight: "bold",
      margin: theme.spacing(4, 0, 4, 0),
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    }));
    const [t, i18n] = useTranslation("global");
  
    if (state.succeeded) {
      return <Title>Thanks for joining!</Title>;
    }
    return (
      <Container maxWidth="sm">
        <Paper elevation={3} style={paperStyle}>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name"></label>
            <Input
              sx={itemsStyle}
              placeholder={t("form.name")}
              id="name"
              type="text"
              name="name"
              fullWidth
            />
            <label htmlFor="lastname"></label>
            <Input
              sx={itemsStyle}
              placeholder={t("form.lastName")}
              id="lastname"
              type="text"
              name="lastname"
              fullWidth
            />
            <label htmlFor="email"></label>
            <Input
              sx={itemsStyle}
              placeholder={t("form.email")}
              id="email"
              type="email"
              name="email"
              fullWidth
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
            <TextField
              placeholder={t("form.placeholderMesaje")}
              sx={itemsStyle}
              id="message"
              name="message"
              rows={5}
              fullWidth
              multiline
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={state.submitting}
              sx={{ marginTop: "10px" }}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    );
  }
  