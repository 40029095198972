import React from "react";
import { Context } from "../App";

import {
  Box,
  styled,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import Container from "@mui/material/Container";
import Logotaylor from "../media/Group 12.svg";
//import caminos from "../media/caminos.jpg";
import caminos from "../media/card.jpg";
import cardCatarsis from "../media/cardcatarsis.jpg";
import { useTranslation } from "react-i18next";
 
function ExperiencesCard({ title, image, description, calentlyURL, fecha,fecha2}) {
  return (
    <Card sx={{ maxWidth: 400, margin: "10px" }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="Woorkshop image"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" align="center">
            {title.toUpperCase()}
          </Typography>
          <Typography
            variant="body2"
            sx={{ minHeight: "150px" }}
            color="text.secondary"
          >
            {description}
          </Typography>
          <Typography
            variant="body2"
            sx={{ minHeight: "15px" }}
            color="text.secondary"
          >
            <strong>{fecha}</strong> {fecha2}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: "center" }}>
        <a  href={calentlyURL} style={{ textDecoration: "none", width: '100%' }}>
          <Button
            sx={{width: "100%"}}
            size="small"
            color="primary"
            variant="outlined"
          >
            Reserva tu espacio
          </Button>
        </a>
      </CardActions>
    </Card>
  );
}

export default function Experiences() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(4),
    margin: theme.spacing(3, 0, 3, 0),
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "2.5rem",
    color: "#000000",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#000000", 
    fontWeight: "bold",
    fontFamily: "Lato",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  const cardsData = [
    {
      title: "Workshop Taylor Swift",
      image: Logotaylor,
      description:
        "¿Quieres vivir una experiencia catártica inspirada en Taylor Swift de la mano de psicólogos y creativos? Únete a nuestro Workshop Taylor Swift y lleva tu trabajo personal a un nivel completamente nuevo. Nuestro enfoque te brinda la libertad y la expresión para capitalizar lo que has vivido y convertirlo en algo poderoso. Tu historia merece ser contada.",
      fecha: "Fecha:",
      fecha2: "8 junio 2024",
      calentlyURL: "https://calendly.com/catarsislabadm/30min"
      
    },
    {
      title: "La conciencia de la cotidianidad",
      image: caminos,
      description:" Un psicólogo te acompañará durante una semana, mientras un creativo trabajará contigo para capitalizar la expresión creativa de lo que en esos dias descubras de ti mismo. Te acompañamos desde tu rutina diaria en el viaje de reflexión y descubrimiento.",
      calentlyURL: "https://calendly.com/catarsislabadm/intro-exp-catarsislab-conciencia-de-la-cotidianidad"
    },
    {
      title: "personaliza tu experiencia",
      image: cardCatarsis,
      description:
        "¡Tu historia nos inspira! Si buscas una experiencia diseñada exclusivamente para ti, puedes contactarnos. Estamos aquí para escucharte, comprender tus necesidades y crear una experiencia terapéutica única para ti. ¡Juntos, vamos a explorar y transformar!",
      calentlyURL: "https://calendly.com/catarsislabadm/30min"
    },
  ];

  return (
    <Context.Consumer>
      {({ setLanguage, theme }) => (
        <div>
          {/* Catarsis lab */}
          <Box
            sx={{
              backgroundColor: "#F1FBFF",
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "1", textAlign: "center" }}>
                  <Title variant="h1">
                  {t("experiences.title")}
                  </Title>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Lato",
                      ml: "10%",
                      mr: "10%",
                    }}
                  >
                    {t("experiences.text1")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#F1FBFF",
              mt: -5,
              "@media (max-width: 600px)": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            {cardsData.map((card, index) => (
              <ExperiencesCard key={index} {...card} />
            ))}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F1FBFF",
              minHeight: "10vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "1", textAlign: "center" }}>
                  <Paragraph
                    variant="body2"
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Lato",
                      ml: "10%",
                      mr: "10%",
                    }}
                  >
                    {t("experiences.text2")}
                  </Paragraph>
                </Box>
              </CustomBox>
            </Container>
          </Box>
        </div>
      )}
    </Context.Consumer>
  );
}
