import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";

import image1 from "../media/1.Ilustracion carrousel.svg";
import image2 from "../media/2.Ilustracion carrousel.svg";
import image3 from "../media/3.Ilustracion carrousel.svg";
import image4 from "../media/4.Ilustracion carrousel.svg";

function CustomCarousel(props) {
  var items = [
    {
      image: image1,
    },
    {
      image: image2,
    },
    {
      image: image3,
    },
    {
      image: image4,
    },
  ];

  const handleSlideChange = (index) => {
    props.onChange(index); 
  }; 

  return (
    <Carousel key="asd" index={props.currentIndex} indicators={false} onChange={handleSlideChange}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Paper
      elevation={0}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={props.item.image}
        alt={props.item.caption}
        style={{ objectFit: "cover", width: "60%", height: "60%" }}
      />
    </Paper>
  );
}

export default CustomCarousel;
