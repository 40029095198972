import React, { useState, useEffect } from "react";
import { Context } from "../App";

import { Box, styled, Typography, Link } from "@mui/material";
import Container from "@mui/material/Container";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

import footer from "../media/Logo Catarsis (1).svg";
import footerCatarsis from "../media/footerCatarsis.svg";
import Newsletterform from "../pages/Newsletterform";

import { useTranslation } from "react-i18next";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://mycatarsis.com/">
        Catarsis Lab
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function AboutUs() {
  const [t, i18n] = useTranslation("global");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    color: "#687690",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  }));

  const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    color: "white",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 1.5, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  }));

  const icons = {
    color: "#ffffff",
    fontSize: "50px",
  };
  /* const [imageWidth, setImageWidth] = useState(
    window.innerWidth > 960 ? "70%" : "50%"
  );

  useEffect(() => {
    const handleResize = () => {
      setImageWidth(window.innerWidth > 960 ? "70%" : "50%");
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); */

  return (
    <Context.Consumer>
      {({ setLanguage }) => (
        <div>
          <Box
            sx={{
              backgroundColor: "#075B69",
              minHeight: "10vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Container>
              <CustomBox>
                <Box sx={{ flex: "1" }}>
                  <img
                    src={footerCatarsis}
                    alt="footerCatarsis"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
                <Box sx={{ flex: "1.5", display: "flex",
              alignItems: "center", }}>
                  <Newsletterform/>
                </Box>
                <Box sx={{ flex: "0.5", textAlign: "end", alignContent: "center", margin: "20px" }}>
                  <FacebookIcon sx={icons} />
                  <InstagramIcon sx={icons} />
                  <TwitterIcon sx={icons} />
                </Box>
              </CustomBox>
            </Container>
          </Box>
          <Box
            sx={{
              minHeight: "10vh",
              textAlign: "center",
              mt: {
                xs: "-73%", // Para dispositivos extra pequeños
                sm: "-30%", // Para dispositivos pequeños
                md: "-21%", // Para dispositivos medianos
                lg: "-21%", // Para dispositivos grandes
                xl: "-15%", 
              },
              width: "100%",
              textAlign: "center",
              zIndex: -10,
              img: {
                width: {
                  xs: "0%", // Para dispositivos extra pequeños
                  sm: "0", // Para otros tamaños de pantalla
                  md: "70%",
                  xl: "50%"
                },
                height: "auto",
                marginLeft: {
                  xs: "-8%", // Para dispositivos extra pequeños
                  sm: "-10%", // Para otros tamaños de pantalla
                  md: "-30%",
                  xl: "-50%"
                },
              },
            }}
          >
            <img src={footer} alt="footer" />
          </Box>
          {/* <Box
            sx={{
              backgroundColor: "#075B69",
              minHeight: "3vh",
            }}
          >
            <Container sx={{ display: "flex", justifyContent: "center" }}>
              <Copyright />
            </Container>
          </Box> */}
        </div>
      )}
    </Context.Consumer>
  );
}
